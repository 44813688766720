<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Mis pagos</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-end align-items-center justify-content-lg-start"
            >
              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por ID de pago o número de factura"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />
              <b-button
                  :to="{ name: 'upload-voucher-empty' }"
                  variant="none"
                  class="button button-primary ml-2"
                  v-if="user && user.type === 2"
              >Subir comprobante
              </b-button>
            </div>
          </section>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>ID</span>
            </div>

            <div>
              <span>Fecha de registro</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.code }}</span>
                </div>

                <div>
                  <span v-localDate="o.registerDate"></span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <a
                  href=""
                  @click="download(o.voucher.asset)"
                  v-if="o.voucher"
                  class="mr-2"
                  >Descargar comprobante</a
                >

                <router-link
                  :to="{ name: 'upload-voucher', params: { code: o.code } }"
                  v-if="o.status === PaymentStatus.PENDING.key && !o.disabled"
                  class="mr-2"
                >
                  Subir comprobante
                </router-link>

                <a
                  href=""
                  @click="seeRejectReason(o.rejectReason)"
                  v-if="o.status === PaymentStatus.REJECTED.key"
                  class="mr-2"
                  >Ver motivo de rechazo</a
                >

                <router-link
                  :to="{
                    name: 'upload-credit-note',
                    params: { code: o.code },
                  }"
                  v-if="o.status === PaymentStatus.REJECTED.key && !o.disabled"
                  class="mr-2"
                >
                  Subir nota de crédito
                </router-link>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Descripción de pago:</span
                    >
                    <span>{{ truncateText(o.description, 70) }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Monto a facturar:</span
                    >
                    <span v-decimal:pen="o.amount"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Número de comprobante:</span
                    >
                    <span>{{ o.voucherCode || "-" }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Fecha de comprobante:</span
                    >
                    <span
                      v-localDate="o.voucher ? o.voucher.issueDate : null"
                    ></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                        o.statusDesc ? o.statusDesc : getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Fecha de registro</th>
              <th>Descripción de pago</th>
              <th>Monto a facturar</th>
              <th>Número de comprobante</th>
              <th>Fecha de comprobante</th>
              <th>Estado</th>
              <th class="pr-md-2">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center">{{ o.code }}</td>
              <td class="cell-center" v-localDate="o.registerDate"></td>
              <td>{{ truncateText(o.description, 70) }}</td>
              <td class="cell-center" v-decimal:pen="o.amount"></td>
              <td class="cell-center">{{ o.voucherCode }}</td>
              <td
                class="cell-center"
                v-localDate="o.voucher ? o.voucher.issueDate : null"
              ></td>
              <td class="cell-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                    o.statusDesc ? o.statusDesc : getStatus(o.status).label
                }}</span>
              </td>

              <td class="cell-center pr-md-2">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      @click="download(o.voucher.asset)"
                      text="Descargar comprobante"
                      v-if="o.voucher"
                    />
                    <DropdownItem
                      text="Subir comprobante"
                      :to="{ name: 'upload-voucher', params: { code: o.code } }"
                      v-if="
                        o.status === PaymentStatus.PENDING.key && !o.disabled
                      "
                    />
                    <DropdownItem
                      text="Ver motivo de rechazo"
                      @click="seeRejectReason(o.rejectReason)"
                      v-if="o.status === PaymentStatus.REJECTED.key"
                    />
                    <DropdownItem
                      text="Subir nota de crédito"
                      :to="{
                        name: 'upload-credit-note',
                        params: { code: o.code },
                      }"
                      v-if="
                        o.status === PaymentStatus.REJECTED.key && !o.disabled
                      "
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>

    <RejectReasonModal
      :show="showRejectReasonModal"
      @hide="showRejectReasonModal = false"
      :reject-reason="selectedRejectReason"
    />
  </div>
</template>

<script>
import { Constants as LocalConstants } from "@/core/utils";
import { PaymentService } from "@/core/services";
import {
  PaginationMixin,
  SearchBar,
  DropdownTable,
  DropdownItem,
  Util,
} from "wize-admin";

import { RejectReasonModal } from "@/core/components";
import { mapGetters } from "vuex";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    RejectReasonModal,
  },
  name: "PaymentsPage",
  mixins: [PaginationMixin],
  data() {
    return {
      tooltipType: null,
      list: [],
      listOriginal: [],
      PaymentStatus: LocalConstants.PaymentStatus,
      showRejectReasonModal: false,
      selectedRejectReason: null,
    };
  },
  props: {
    title: String,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PaymentStatus);
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
        };
        const data = { search: this.search };
        const response = await PaymentService.getPayments(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.listOriginal = this.list;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        // TODO Handle error
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    download(asset) {
      if (asset) Util.downloadFile(asset.url, asset.name);
    },
    seeRejectReason(rejectReason) {
      this.selectedRejectReason = rejectReason;
      this.showRejectReasonModal = true;
    },
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },
  },
  async mounted() {
    await this.loadData(this.page, this.pageSize);
  },
};
</script>

<style lang="stylus" scoped></style>
